import router from './components/router';
import { container } from './components/store';
import back from '../assets/img/back.svg';
import buddha from '../assets/img/buddha_logo_final.png';
import sriYantra from '../assets/img/shri_yantra.png';
import footer from './components/footer';

export function howto() {
  container.innerHTML = `
<div class="header"> 
  <div class="container">
    <div class="col-33-percent">
      <a href="/#/" class="back-button">${back}</a>
    </div>
    <div class="col-33-percent text-center">
      <img class="buddha-icon" src=${buddha} />
    </div>
    <div class="col-33-percent text-center">
    </div>
  </div>
</div>

<section id='section-container'>
<div class="container"> 
  <div class="about-yantra">
    <h3 id="about-yantra-title">How to Meditate with Yantras</h3>
    <p>Place the yantra in front of you. Be sure to position the yantra so that when you look at the diagram, from your sitting position, your head is held straight ahead and you are not looking with your head tilted up or down. You may want to use the yantra by raising the screen to just the right height so that when you face the screen and gaze at the image, your head is held straight. It is essential to keep your head at a straight angle during meditation so that the kundalini energy that is unlocked during meditation can move up your chakras freely.</p> 
    <div id="yantra-icon">
      <img class="yantra-img" src=${sriYantra} style="width: 30%">
    </div>

    <p>During meditation, simply gaze gently at the bindu (the dot in the middle of the interlocking triangles.) Keep gazing gently at the bindu during your meditation period.</p>

    <p>If your eyes get tired you can close your eyes for a few seconds then reopen them and come right back to gazing gently at the diagram.</p>

    <p>If you grow tired of focusing on the bindu in the center, you can focus on the diagram in its entirety, or focus on a specific set of interconnected triangles that jump out at you.</p>

    <p>During yantra meditation, you may experience phenomenon. The triangles may start to move, or a set of triangles may appear to pop out or change colors compared to the others. The wall surrounding the diagram, the entire room may start to turn colors. You may feel sensations in your body. You might find yourself feeling happy and full of energy. Whatever happens, keep your head held straight ahead and keep focusing gently on the yantra.</p>

    <p>A yantra is like a machine - it's constantly emitting the energy it's associated with. Therefore, it's a good idea to display the sri yantra in your home at all times, as it will bring positive energy into your space. For example, it's a good idea to keep the yantra displayed in your meditation area even when you are not using it for meditation practice.</p>

    <p>~Enjoy</p>
  </div>
</div>
</section>
`
  container.appendChild(footer);
};
