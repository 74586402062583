import 'normalize.css/normalize.css';
import './styles/index.scss';
import { setRoutes } from './js/components/router';
import NoSleep from 'nosleep.js';

let noSleep = new NoSleep();

window.onload = () => {
  setRoutes();
  document.addEventListener('click', function enableNoSleep() {
    document.removeEventListener('click', enableNoSleep, false);
    noSleep.enable();
  }, false);
}
