import router from './components/router';
import sleep from './sleep';
import store,
  {
    body,
    setStore,
    Yantra,
    pauseBtnImageSVG,
    playBtnImageSVG,
    closeBtnImage,
    container,
    startTimer,
    getSongs,
    yantra1,
    yantra2,
    yantra3,
    yantra4,
    yantra5,
    yantra6,
    fullScreenImg,
    exitFullScreenImg } from './components/store';

const yantras = [yantra1, yantra2, yantra3, yantra4, yantra5, yantra6];

Yantra.innerHTML = `
  <div id="yantra1"><img class="yantra1" src="${yantra1}" alt="yantra1" /></div>
  <div id="yantra2"><img class="yantra2" src="${yantra2}" alt="yantra2" /></div>
  <div id="yantra3"><img class="yantra3" src="${yantra3}" alt="yantra3" /></div>
  <div id="yantra4"><img class="yantra4" src="${yantra4}" alt="yantra4" /></div>
  <div id="yantra5"><img class="yantra5" src="${yantra5}" alt="yantra5" /></div>
  <div id="yantra6"><img class="yantra6" src="${yantra6}" alt="yantra6" /></div>
`;
const playerContainer = document.createElement("DIV");
const CloseBtn = document.createElement("DIV");
CloseBtn.id = "closeBtn";
CloseBtn.innerHTML = closeBtnImage;

const PlayPauseButton = document.createElement("img");
PlayPauseButton.id = "playPauseBtn";
PlayPauseButton.src = pauseBtnImageSVG;

const FullScreenButton = document.createElement("img");
FullScreenButton.id = "fullScreenBtn";
FullScreenButton.src = fullScreenImg;

function goBackHome() {
  setStore({selected: false, numberOfSongs: 1, playTime: 'morning'});
  router.navigate('/');
  closeFullscreen();
  resetYantras();
  PlayPauseButton.src = pauseBtnImageSVG;
  FullScreenButton.src = fullScreenImg;
}

function resetYantras() {
  document.getElementById('yantra1').style.display = "block";
  document.getElementById('yantra2').style.display = "block";
  document.getElementById('yantra3').style.display = "block";
  document.getElementById('yantra4').style.display = "block";
  document.getElementById('yantra5').style.display = "block";
  document.getElementById('yantra6').style.display = "block";
}

function playPauseFunction() {
  if (store.currentlyPlaying) {
    store.currentAudio.pause();
    store.currentlyPlaying = false;
    PlayPauseButton.src = playBtnImageSVG;
  } else {
    store.currentAudio.play();
    store.currentlyPlaying = true;
    PlayPauseButton.src = pauseBtnImageSVG;
  }
};

function setYantraImage(className, image) {
  const yantraHtml = `
    <img class= "${className}" src="${image}" alt="yantra" />
  `;
  return yantraHtml;
}

function showHidePlayPauseBtn() {
  if(PlayPauseButton.classList.value === 'hide') {
    PlayPauseButton.classList.remove('hide');
    CloseBtn.classList.remove('vis-hidden');
    FullScreenButton.classList.remove('vis-hidden');
    setTimeout(() => {
      PlayPauseButton.classList.add('hide');
      CloseBtn.classList.add('vis-hidden');
      FullScreenButton.classList.add('vis-hidden');
    }, 3000);
  }
}

function setEventListeners() {
  PlayPauseButton.removeEventListener('click', playPauseFunction);
  PlayPauseButton.addEventListener('click', playPauseFunction);

  playerContainer.removeEventListener("mousemove", showHidePlayPauseBtn, false);
  playerContainer.addEventListener("mousemove", showHidePlayPauseBtn, false);

  playerContainer.removeEventListener("click", showHidePlayPauseBtn, false);
  playerContainer.addEventListener("click", showHidePlayPauseBtn, false);

  body.removeEventListener("keypress", showHidePlayPauseBtn, false);
  body.addEventListener("keypress", showHidePlayPauseBtn, false);

  body.removeEventListener("keypress", playPauseFunction, false);
  body.addEventListener("keypress", playPauseFunction, false);

  CloseBtn.removeEventListener('click', goBackHome);
  CloseBtn.addEventListener('click', goBackHome);

  FullScreenButton.removeEventListener('click', fullScreenFunction);
  FullScreenButton.addEventListener('click', fullScreenFunction);
}

export function player() {
  body.classList.remove('black-bg');

  store.location = 'player';
  container.innerHTML = '';
  playerContainer.innerHTML = '';

  container.appendChild(playerContainer);

  const { numberOfSongs, playTime } = store;

  playerContainer.appendChild(CloseBtn);
  CloseBtn.classList.add('vis-hidden');

  playerContainer.appendChild(FullScreenButton);
  FullScreenButton.classList.add('vis-hidden');

  playerContainer.appendChild(Yantra);
  resetYantras();

  playerContainer.appendChild(PlayPauseButton);

  PlayPauseButton.classList.add('hide');

  setEventListeners();

  // get songs
  const songs = getSongs(numberOfSongs, playTime);

  // create audios
  const audios = songs.map((song, idx) => {
    const audio = new Audio();
    audio.src = song;
    audio.setAttribute('id', `song${idx + 1}`);
    audio.setAttribute('type', 'audio/mp3');
    // audio.setAttribute('controls', '');
    return audio;
  });

  // add audios to the playerContainer
  audios.forEach(audio => {
    playerContainer.appendChild(audio);
  });

  // play first song
  const firstSong = document.getElementById('song1');
  firstSong.play().catch(e => {
    alert('Please press play to start the song or download our app for a better experience.');
    PlayPauseButton.src = playBtnImageSVG;
  }).then(() => {
    sleep.prevent();
  });
  store.currentAudio = firstSong;
  store.currentlyPlaying = true;
  showHidePlayPauseBtn();

  // change the yantras
  const changeYantra = yantraNumber => {
    const yantraDiv = document.getElementById(`yantra${yantraNumber}`);
    yantraDiv.style.display = "none";
    sleep.prevent()
    return yantraNumber + 1
  };

  const yantraNum = 1;

  // start timers to change the yantras
  if (numberOfSongs === 1 ) {
    startTimer.oneSong(yantraNum)
    .then(changeYantra)
    .then(yantraNumber => startTimer.oneSong(yantraNumber))
    .then(changeYantra)
    .then(yantraNumber => startTimer.oneSong(yantraNumber))
    .then(changeYantra)
    .then(yantraNumber => startTimer.oneSong(yantraNumber))
    .then(changeYantra)
    .then(yantraNumber => startTimer.oneSong(yantraNumber))
    .then(changeYantra);
  } else {
    startTimer.navel(yantraNum)
    .then(changeYantra)
    .then(yantraNumber => startTimer.navel(yantraNumber))
    .then(changeYantra)
    .then(yantraNumber => startTimer.heart(yantraNumber))
    .then(changeYantra)
    .then(yantraNumber => startTimer.heart(yantraNumber))
    .then(changeYantra)
    .then(yantraNumber => startTimer.thirdEye(yantraNumber))
    .then(changeYantra);
  }
  

  // add listeners to play one song after the previous ends
  for(let i = 1 ; i <= numberOfSongs ; i++) {
    const previousAudio = document.getElementById(`song${i}`);
    const nextAudio = document.getElementById(`song${i + 1}`);
    previousAudio.onended = () => {
      if(i === numberOfSongs) {
        goBackHome();
      } else {
        nextAudio.play();
        store.currentAudio = nextAudio;
      }
    };
  }
}

// full screen view
function openFullscreen() {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) { /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE/Edge */
    elem.msRequestFullscreen();
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { 
    document.msExitFullscreen();
  }
}

function fullScreenFunction() {
  if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
    openFullscreen();
    FullScreenButton.src = exitFullScreenImg;
  } else {
    closeFullscreen();
    FullScreenButton.src = fullScreenImg;
  }
}
