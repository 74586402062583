import mn1 from '../../assets/audio/morning/morning_navel_1.mp3';
import mn2 from '../../assets/audio/morning/morning_navel_2.mp3';
import mn3 from '../../assets/audio/morning/morning_navel_3.mp3';
import mn4 from '../../assets/audio/morning/morning_navel_4.mp3';
import mn5 from '../../assets/audio/morning/morning_navel_5.mp3';

import mh1 from '../../assets/audio/morning/morning_heart_1.mp3';
import mh2 from '../../assets/audio/morning/morning_heart_2.mp3';
import mh3 from '../../assets/audio/morning/morning_heart_3.mp3';
import mh4 from '../../assets/audio/morning/morning_heart_4.mp3';
import mh5 from '../../assets/audio/morning/morning_heart_5.mp3';

import mte1 from '../../assets/audio/morning/morning_third_eye_1.mp3';
import mte2 from '../../assets/audio/morning/morning_third_eye_2.mp3';
import mte3 from '../../assets/audio/morning/morning_third_eye_3.mp3';
import mte4 from '../../assets/audio/morning/morning_third_eye_4.mp3';
import mte5 from '../../assets/audio/morning/morning_third_eye_5.mp3';

export const morningNavel = [ mn1, mn2, mn3, mn4, mn5 ];
export const morningNavelDuration = [ 281000, 290000, 272000, 283000, 302000 ];

export const morningHeart = [ mh1, mh2, mh3, mh4, mh5 ];
export const morningHeartDuration = [ 246000, 252000, 243000, 244000, 309000 ];

export const morningThirdEye = [ mte1, mte2, mte3, mte4, mte5 ];
export const morningThirdEyeDuration = [ 293000, 240000, 252000, 206000, 256000 ];
