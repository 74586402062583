const selectSongHtml = `
<select id="selectSongs" name="me">
  <option value="no selected">Select Playlist</option>
  <option value="1 morning song">1 morning song</option>
  <option value="3 morning song">3 morning songs</option>
  <option value="6 morning song">6 morning songs</option>
  <option value="9 morning song">9 morning songs</option>
  <option value="1 evening song">1 evening song</option>
  <option value="3 evening song">3 evening songs</option>
  <option value="6 evening song">6 evening songs</option>
  <option value="9 evening song">9 evening songs</option>
</select>
`;

const SelectSong = document.createElement("DIV");
SelectSong.innerHTML = selectSongHtml;

export default SelectSong;
