import router from './components/router';
import store, { body, setStore, container, SelectSong, PlayButton, buddha } from './components/store';
import HomeMenu from './components/HomeMenu';
import footer from './components/footer';
import banner from './components/banner';

export function home() {
  store.location = 'home';
  store.currentlyPlaying = false;
  body.classList.add('black-bg');
  container.innerHTML = '';
  let centeredContainer = document.createElement("div");
  centeredContainer.classList.add('text-center');
  container.appendChild(centeredContainer);
  centeredContainer.innerHTML = `<img class="buddha-icon" src=${buddha} />`;
  container.appendChild(banner);
  centeredContainer.appendChild(SelectSong);

  if(!store.selected) {
    const selectElement = document.getElementById('selectSongs');
    selectElement.value = 'no selected';
  }

  centeredContainer.appendChild(PlayButton);

  centeredContainer.appendChild(HomeMenu);

  PlayButton.addEventListener('click', () => {
    router.navigate('player');
  });

  if(!store.selected) {
    PlayButton.classList.add("hide");
  }

  SelectSong.onchange = (event) => {
    if (event.target.value === 'no selected') {
      setStore({selected: false, numberOfSongs: 1, playTime: 'morning'});
    }
    if (event.target.value === '1 morning song') {
      setStore({selected: true, numberOfSongs: 1, playTime: 'morning'});
    }
    if (event.target.value === '3 morning song') {
      setStore({selected: true, numberOfSongs: 3, playTime: 'morning'});
    }
    if (event.target.value === '6 morning song') {
      setStore({selected: true, numberOfSongs: 6, playTime: 'morning'});
    }
    if (event.target.value === '9 morning song') {
      setStore({selected: true, numberOfSongs: 9, playTime: 'morning'});
    }
    if (event.target.value === '1 evening song') {
      setStore({selected: true, numberOfSongs: 1, playTime: 'evening'});
    }
    if (event.target.value === '3 evening song') {
      setStore({selected: true, numberOfSongs: 3, playTime: 'evening'});
    }
    if (event.target.value === '6 evening song') {
      setStore({selected: true, numberOfSongs: 6, playTime: 'evening'});
    }
    if (event.target.value === '9 evening song') {
      setStore({selected: true, numberOfSongs: 9, playTime: 'evening'});
    }

    if(store.selected) {
      PlayButton.classList.remove('hide');
    } else {
      PlayButton.classList.add('hide');
    }
  }
  container.appendChild(footer);
};
