import assert from 'assert';
import {
  morningNavel,
  morningHeart,
  morningThirdEye,
  morningNavelDuration,
  morningHeartDuration,
  morningThirdEyeDuration
} from '../playlists/morningPlaylists';
import {
  eveningNavel,
  eveningHeart,
  eveningThirdEye,
  eveningNavelDuration,
  eveningHeartDuration,
  eveningThirdEyeDuration
} from '../playlists/eveningPlaylists';
import store from './store';

const getRandomIndexArray = numberOfSongs => {

  const indexes = [];
  // build an array of non repeated indexes with length numberOfSongs
    do {
      const randomIndex = Math.floor(Math.random()*5);
      if (!indexes.includes(randomIndex)) {
        indexes.push(randomIndex);
      }
    } while(indexes.length < numberOfSongs)

  return indexes;
};

const getSongs = (numberOfSongs, timeOfMeditation) => {
  assert(numberOfSongs === 1 || numberOfSongs === 3 || numberOfSongs === 6 || numberOfSongs === 9,
    `In getSongs function, 'numberOfSongs' should be a number in [1,3,6,9]`
  );
  assert(timeOfMeditation === 'morning' || timeOfMeditation === 'evening',
    `In getSongs function, ${timeOfMeditation} is an invalid 'timeOfMeditation'. 'timeOfMeditation' needs to be 'morning' or 'evening'`
  );

  const songs = [];
  let oneSongDuration = 0;
  let navelDuration = 0;
  let heartDuration = 0;
  let thridEyeDuration = 0;

  if (numberOfSongs === 1) {
    const songIndex = getRandomIndexArray(numberOfSongs);

    if (timeOfMeditation === 'morning') {
      // push to songs morningHeart songs with random unique indexes
      songs.push(morningHeart[songIndex]);
      oneSongDuration = morningHeartDuration[songIndex];
    } else {
      songs.push(eveningHeart[songIndex]);
      oneSongDuration = eveningHeartDuration[songIndex];
    }

  } else {
    let repeateEachGroup;
    if (numberOfSongs === 3) {
      repeateEachGroup = 1;
    } else if (numberOfSongs === 6) {
      repeateEachGroup = 2;
    } else if (numberOfSongs === 9) {
      repeateEachGroup = 3;
    }
    const navelSongIndex = getRandomIndexArray(repeateEachGroup);
    const heartSongIndex = getRandomIndexArray(repeateEachGroup);
    const thirdEyeSongIndex = getRandomIndexArray(repeateEachGroup);

    if (timeOfMeditation === 'morning') {

      navelSongIndex.forEach(index => {
        songs.push(morningNavel[index]);
        navelDuration += morningNavelDuration[index];
      });

      heartSongIndex.forEach(index => {
        songs.push(morningHeart[index]);
        heartDuration += morningHeartDuration[index];
      });

      thirdEyeSongIndex.forEach(index => {
        songs.push(morningThirdEye[index]);
        thridEyeDuration += morningThirdEyeDuration[index];
      });

    } else {
      navelSongIndex.forEach(index => {
        songs.push(eveningNavel[index]);
        navelDuration += eveningNavelDuration[index];
      });

      heartSongIndex.forEach(index => {
        songs.push(eveningHeart[index]);
        heartDuration += eveningHeartDuration[index];
      });

      thirdEyeSongIndex.forEach(index => {
        songs.push(eveningThirdEye[index]);
        thridEyeDuration += eveningThirdEyeDuration[index];
      });

    }
  }

  store.currentPlaylistOneSongDuration = oneSongDuration;
  store.currentPlaylistNavelDuration = navelDuration;
  store.currentPlaylistHeartDuration = heartDuration;
  store.currentPlaylistThirdEyeDuration = thridEyeDuration;

  return songs;
};

export default getSongs;
