import { pauseBtnImageSVG } from "./store";

const playPauseHtml = `
  <svg id="playPause" src=${pauseBtnImageSVG}></svg>
`;

const PlayPauseButton = document.createElement("DIV");
PlayPauseButton.innerHTML = playPauseHtml;

export default PlayPauseButton;
