import router from './components/router';
import { body, container } from './components/store';
import back from '../assets/img/back.svg';
import buddha from '../assets/img/buddha_logo_final.png';
import sriYantra from '../assets/img/shri_yantra.png';
import footer from './components/footer';

export function about() {
  body.classList.add('black-bg');
  container.innerHTML = `
<div class="header"> 
  <div class="container">
    <div class="col-33-percent">
      <a href="/#/" class="back-button">${back}</a>
    </div>
    <div class="col-33-percent text-center">
      <img class="buddha-icon" src=${buddha} />
    </div>
    <div class="col-33-percent text-center">
    </div>
  </div>
</div>

<section id='section-container'>
<div class="container"> 
  <div class="about-yantra">
    <h3 id="about-yantra-title">About Yantras</h3>
    <p>A yantra is a mystical diagram; a
    geometric depiction of a universal truth.
    The image is like a portal to a universe, a
    dimension, a state of mind, or an energy.
    Yantras have been used for thousands of
    years for meditation. Yantras are an
    excellent tool for developing
    concentration.</p> 
    <div id="yantra-icon">
      <img class="yantra-img" src=${sriYantra} style="width: 30%">
    </div>   

    <p>Yantra meditation is very powerful. These
    sacred diagrams can carry us into higher
    states of mind, to experience higher
    understandings and sacred energies.</p>

    <p>Science has proven that everything in
    our world is based on geometry.
    Mysteriously reoccurring mathematical
    proportions, for example, the Fibonacci
    Sequence and the Golden Ratio appear
    everywhere around us. They are
    displayed in the proportions of flower
    petals, animal horns, the human body,
    crystals, star constellations,
    molecular design, and physics.
    Humans are drawn to and find an innate
    beauty in these proportions.
    As there are infinite dimensions in the
    universe, humans have been able to
    depict these energies via many
    geometrical designs. For example – the
    energy of dissolution; away from the
    physical and into the spiritual (as associated
    with Shiva), is depicted by an upward
    pointing triangle in front of a downward
    pointing triangle. Another example is the
    Flower of Life, which depicts the energy of
    the 12 of the universe. It’s made up of 19
    interconnected and overlapping circles.
    Another example is the Kalachakra
    mandala, another yantra which depicts
    the universe of the Buddha Kalachakra
    and conveys the energy of
    enlightenment, of going beyond time
    and space.</p>

    <p>The yantra found here is the Sri Yantra –
    the Holy Yantra – also known as the
    highest yantra. This sacred diagram is
    formed of nine interlocking triangles with
    a bindu or a dot in the center. This yantra
    depicts the energy which holds the entire
    cosmos. It depicts the union of yin and
    yang. This diagram is magical. It holds
    secret teachings and can carry you to
    connect with infinity, with all things
    beneficial, with power, love, beauty,
    abundance, and knowledge.</p>
  </div>
</div>
</section>
`
  container.appendChild(footer);
};
