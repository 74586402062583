import store from './store';

const oneSongTimer = yantra => new Promise((resolve, reject) => {
  store.currentPlayTime = 0;

  const intervalID = setInterval(() => {
    if(store.location !== 'player') {
      clearInterval(intervalID);
    }

    if (store.currentlyPlaying) {
      store.currentPlayTime += 1000;
    }

    if (store.currentPlayTime >= store.currentPlaylistOneSongDuration/6) {
      clearInterval(intervalID);
      resolve(yantra);
    }
  }, 1000);
});

const navelTimer = yantra => new Promise((resolve, reject) => {
  store.currentPlayTime = 0;

  const intervalID = setInterval(() => {
    if(store.location !== 'player') {
      clearInterval(intervalID);
    }

    if (store.currentlyPlaying) {
      store.currentPlayTime += 1000;
    }

    if (store.currentPlayTime >= store.currentPlaylistNavelDuration/2) {
      clearInterval(intervalID);
      resolve(yantra);
    }
  }, 1000);
});

const heartTimer = yantra => new Promise((resolve, reject) => {
  store.currentPlayTime = 0;

  const intervalID = setInterval(() => {
    if(store.location !== 'player') {
      clearInterval(intervalID);
    }

    if (store.currentlyPlaying) {
      store.currentPlayTime += 1000;
    }

    if (store.currentPlayTime >= store.currentPlaylistHeartDuration/2) {
      clearInterval(intervalID);
      resolve(yantra);
    }
  }, 1000);
});

const thirdEyeTimer = yantra => new Promise((resolve, reject) => {
  store.currentPlayTime = 0;

  const intervalID = setInterval(() => {
    if(store.location !== 'player') {
      clearInterval(intervalID);
    }

    if (store.currentlyPlaying) {
      store.currentPlayTime += 1000;
    }

    if (store.currentPlayTime >= store.currentPlaylistThirdEyeDuration/2) {
      clearInterval(intervalID);
      resolve(yantra);
    }
  }, 1000);
});

const startTimer = {
  oneSong: oneSongTimer,
  navel: navelTimer,
  heart: heartTimer,
  thirdEye: thirdEyeTimer
};

export default startTimer;
