import en1 from '../../assets/audio/night/night_navel_1.mp3';
import en2 from '../../assets/audio/night/night_navel_2.mp3';
import en3 from '../../assets/audio/night/night_navel_3.mp3';
import en4 from '../../assets/audio/night/night_navel_4.mp3';
import en5 from '../../assets/audio/night/night_navel_5.mp3';

import eh1 from '../../assets/audio/night/night_heart_1.mp3';
import eh2 from '../../assets/audio/night/night_heart_2.mp3';
import eh3 from '../../assets/audio/night/night_heart_3.mp3';
import eh4 from '../../assets/audio/night/night_heart_4.mp3';
import eh5 from '../../assets/audio/night/night_heart_5.mp3';

import ete1 from '../../assets/audio/night/night_third_eye_1.mp3';
import ete2 from '../../assets/audio/night/night_third_eye_2.mp3';
import ete3 from '../../assets/audio/night/night_third_eye_3.mp3';
import ete4 from '../../assets/audio/night/night_third_eye_4.mp3';
import ete5 from '../../assets/audio/night/night_third_eye_5.mp3';

export const eveningNavel = [ en1, en2, en3, en4, en5 ];
export const eveningNavelDuration = [ 276000, 268000, 281000, 216000, 277000 ];

export const eveningHeart = [ eh1, eh2, eh3, eh4, eh5 ];
export const eveningHeartDuration = [ 234000, 255000, 270000, 262000, 260000 ];

export const eveningThirdEye = [ ete1, ete2, ete3, ete4, ete5 ];
export const eveningThirdEyeDuration = [ 258000, 207000, 246000, 262000, 295000 ];
