import closeBtnImage from '../../assets/img/close_icon.svg';

let banner = document.createElement('div');

banner.classList.add('banner');

banner.innerHTML = `For more features, please, download the Mobile App:
<a href="https://itunes.apple.com/us/app/yantra-meditation/id1455944934?mt=8" target="_blank">iOS</a> or <a href="https://play.google.com/store/apps/details?id=com.app.yantra" target="_blank">android</a>
`
const CloseBannerBtn = document.createElement("DIV");
CloseBannerBtn.id = "closeBannerBtn";
CloseBannerBtn.innerHTML = closeBtnImage;
banner.appendChild(CloseBannerBtn);

function closeBanner() {
  banner.style.display = 'none';
}

CloseBannerBtn.addEventListener('click', closeBanner);

export default banner;