import SelectSong from './SelectSong';
import PlayButton from './PlayButton';
import PlayPauseButton from './PlayPauseButton';
import playBtnImageSVG from '../../assets/img/play_button.png';
import pauseBtnImageSVG from '../../assets/img/pause.png';
import closeBtnImage from '../../assets/img/close_icon.svg';
import Yantra from './Yantra';
import startTimer from './startTimer';
import getSongs from './getSongs';
import yantra1 from '../../assets/img/yantras/green.png';
import yantra2 from '../../assets/img/yantras/red.png';
import yantra3 from '../../assets/img/yantras/yellow.png';
import yantra4 from '../../assets/img/yantras/whiteblack.png';
import yantra5 from '../../assets/img/yantras/blackwhite.png';
import yantra6 from '../../assets/img/yantras/darkyellow.png';
import buddha from '../../assets/img/buddha_logo_final.png';
import fullScreenImg from '../../assets/img/fullscreen.png';
import exitFullScreenImg from '../../assets/img/exit_fullscreen.png';

const store = {
  location: 'home',
  selected: false,
  numberOfSongs: 1,
  playTime: 'morning',
  currentAudio: null,
  currentlyPlaying: false,
  currentPlaylistOneSongDuration: 0,
  currentPlaylistNavelDuration: 0,
  currentPlaylistHeartDuration: 0,
  currentPlaylistThirdEyeDuration: 0,
  currentPlayTime: 0
};

export default store;

const setStore = ({selected, numberOfSongs, playTime}) => {
  store.selected = selected;
  store.numberOfSongs = numberOfSongs;
  store.playTime = playTime;
}

const container = document.getElementById('container');
const body = document.getElementsByTagName('body')[0];

export {
  body,
  SelectSong,
  PlayButton,
  PlayPauseButton,
  pauseBtnImageSVG,
  playBtnImageSVG,
  closeBtnImage,
  Yantra,
  setStore,
  container,
  startTimer,
  getSongs,
  yantra1,
  yantra2,
  yantra3,
  yantra4,
  yantra5,
  yantra6,
  buddha,
  fullScreenImg,
  exitFullScreenImg
};

window.store = store;
