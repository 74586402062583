import Navigo from 'navigo';
import { about } from '../about';
import { home } from '../home';
import { player } from '../player';
import { howto } from '../howto';

const root = null;
const useHash = true; // Defaults to: false
const hash = '#'; // Defaults to: '#'
const router = new Navigo(root, useHash, hash);

export const setRoutes = () => {
  router
    .on({
      '/': home,
      'about': about,
      'player': player,
      'howto' : howto
    })
    .resolve();

  router.notFound(function () {
    // called when there is path specified but
    // there is no route matching
    container.innerHTML = 'PAGE NOT FOUND';
  }).resolve();
}

export default router;
